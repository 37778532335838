<template>
  <div
    class="home"
    :class="[`home_${qrType}`, { 'is-app': isApp }]"
    @click.self="handleSelf"
  >
    <!-- 搜索框-->
    <div class="h-search_wrap">
      <div class="search-layout">
        <span class="city omit" @click="handleCity">
          <span class="omit">{{ city }}</span>
        </span>
        <i class="icon-arrow-bottom"></i>
        <div class="input-wrap">
          <input
            type="text"
            class="input"
            placeholder="请选择门店"
            v-model="value"
            @focus.stop="handleFocus"
            @input.stop="handleInputDebounce"
          />
        </div>
      </div>
    </div>

    <!-- 二维码 -->
    <div class="h-qrCode_wrap">
      <div
        class="img-wrap"
        v-if="qrCodeUrl"
        :style="setQrCodeImg"
        @touchstart="handleTouchStart"
        @touchend="handleTouchEnd"
      >
        <van-image class="img" :src="qrCodeUrl" :alt="noCodeText">
          <template v-slot:loading>
            <van-loading type="spinner" size="30" />
          </template>
        </van-image>
      </div>
      <!-- 没有token -->
      <div class="no-permission" v-if="noToken">
        <span class="button">去授权</span>
      </div>
      <!-- 没有token && 没有二维码 -->
      <div class="no-permission no-code" v-if="!noToken && !qrCodeUrl">
        <i class="no-img"></i>
        <span class="no-text">暂无{{ noCodeText }}</span>
      </div>
    </div>
    <!-- 下拉框 -->
    <div class="h-store_list">
      <div
        class="store-layout"
        ref="storeLayoutRef"
        :style="setStoreListLayoutStyle"
      >
        <ul class="s-list_wrap">
          <li
            class="l-item l-item_center omit"
            v-if="location.latitude && isCurrentCity"
          >
            为您推荐附近的门店
          </li>
          <li
            class="l-item omit"
            v-for="item in storeList"
            :key="item.storeId"
            @click.stop="handleClickStore(item)"
          >
            {{ item.storeName }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import cityApi from "@/api/cityApi";
import storage from "@/util/storage.js";
import { bridgeMethods } from "@/util/bridge.js";
import { debounce, updatePointData } from "@/util";
const CITY_NAME = "城市";
const titleOption = {
  1: "成为经理好友",
  2: "加入门店社群",
};
export default {
  name: "home",
  data() {
    this.timer = null;
    this.timerOut = null;
    this.timeInterval = null;
    return {
      wxCorpId: "",
      value: "",
      shopNo: "",
      qrCodeUrl: "", // "https://icc-dev.oss-accelerate.aliyuncs.com/userqrcode/f5ac1845adfcc49c8b909c1d802234a0/62c3efa4e4b07c0fdf0c7094.png",
      qrCodeId: "",
      qrCodeType: "",
      indexClass: "", //bu类名
      noCodeText: "二维码",
      storeList: [],
      isFlag: true,
      isStoreList: false,
      isCurrentCity: false,
      city: storage.getStorage("cityName", CITY_NAME),
      location: storage.getStorage("location", {
        latitude: null,
        longitude: null,
      }),
    };
  },
  computed: {
    noToken() {
      return !window._ICC_LBS_DATA.token;
    },
    setStoreListLayoutStyle() {
      return `transform-origin: center top;height:${
        this.isStoreList ? 220 : 0
      }px;transition: all 200ms ease-in;`;
    },
    setQrCodeImg() {
      return `background:url(${this.qrCodeUrl}) no-repeat 0 0/100%;`;
    },
    routerQuery() {
      return storage.getStorage("routerQuery", {
        channelSource: "",
        cid: "",
        userId: "",
      });
    },
    qrType() {
      return this.qrCodeType ? this.qrCodeType : "1";
    },
    isApp() {
      return !!window._ICC_LBS_DATA.urlParams.isApp;
    },
  },
  created() {
    this.qrCodeType = window._ICC_LBS_DATA.urlParams?.qrCodeType;
    this.qrCodeType && (document.title = titleOption[this.qrCodeType]);
    window._ICC_LBS_DATA.token && this.initStoreItem();
  },
  mounted() {
    this.handleInputDebounce = debounce(this.handleInput, 500);
  },
  methods: {
    // 判断是否是CBP
    async isCBP() {
      try {
        const userId = window._ICC_LBS_DATA.urlParams?.userId || null;
        const putChannelId = window._ICC_LBS_DATA.urlParams?.channelSource || null;
        if (userId) {
          const res = await cityApi.isCBP({userId,putChannelId});
          //  "isExpired": true // 是否失效 true 失效 false不失效
          if (!res.data.isExpired) {
            //  不是 确认下是跳转还是弹窗提示
            this.$dialog.alert({
                message:'推荐二维码已失效',
                showConfirmButton: false,
                showCancelButton: false,
                className: "dialog-auth_custom",
              })
              .then(() => {})
              .catch(() => {});
            return;
          }
        }

        await this.initStoreItem();
      } catch (error) {
        console.error("🚀 ~ isCBP ~ error:", error);
      }
    },

    // 判断如果有地址位置，或者有城市名称
    initStoreItem() {
      // app
      if (window._ICC_LBS_DATA.urlParams.isApp) {
        this.handleApp();
      } else {
        this.handleH5();
      }
    },

    // 如果跳转授权页，授权之后，回来重新获取地理位置
    handleCallApp() {
      this.timeInterval = setInterval(() => {
        this.clearInterval();
        this.handleApp();
      }, 1000);
    },

    clearInterval() {
      if (this.timeInterval) {
        clearInterval(this.timeInterval);
        this.timeInterval = null;
      }
    },

    // 麦当劳 App
    handleApp() {
      const _this = this;
      // 如果没有地理位置,也没有选择城市
      if (!this.location.latitude && this.city === CITY_NAME) {
        bridgeMethods
          .getGeoLocation()
          .then((res) => {
            this.clearInterval();
            this.handleSetLocation(res);
          })
          .catch(() => {
            this.$dialog
              .confirm({
                title: "权限请求",
                className: "dialog-auth_custom",
                message:
                  "请授权麦当劳使用位置信息，用于为您推荐附近餐厅及优惠，更好的提供服务。",
              })
              .then(() => {
                // 跳转系统设置页面
                bridgeMethods.goToSetting().then(() => {
                  _this.handleCallApp();
                });
              })
              .catch(() => {
                this.clearInterval();
                bridgeMethods.getUserCity().then((city) => {
                  if (city && city.cityName) {
                    this.city = city.cityName;
                    this.getStoreList();
                  } else {
                    this.$toast("请手动选择城市");
                  }
                });
              });
          });

        return;
      }

      // 如果有城市名称
      if (this.city !== CITY_NAME && this.city) {
        this.getStoreList();
        return false;
      }

      // 没有城市名称，有地理位置
      this.getStoreList();
    },

    // 麦当劳 h5
    handleH5() {
      // 如果没有地理位置,也没有选择城市
      if (!this.location.latitude && this.city === CITY_NAME) {
        this.getLocation();
        return false;
      }

      // 如果有城市名称
      if (this.city !== CITY_NAME && this.city) {
        this.getStoreList();
        return false;
      }

      // 没有城市名称，有地理位置
      this.getStoreList();
    },

    // 获取地理位置
    getLocation() {
      const _this = this;
      if (window._ICC_LBS_DATA.isWXError) return false;
      // eslint-disable-next-line no-undef
      wx.ready(function () {
        // eslint-disable-next-line no-undef
        wx.getLocation({
          type: "gcj02", // 默认为wgs84的 gps 坐标，如果要返回直接给 openLocation 用的火星坐标，可传入'gcj02'
          success(res) {
            // res.latitude; // 纬度，浮点数，范围为90 ~ -90
            // res.longitude; // 经度，浮点数，范围为180 ~ -180。
            _this.handleSetLocation(res);
          },
          fail(err) {
            _this.getLocationError(err);
          },
          cancel(err) {
            _this.getLocationError(err);
          },
        });
      });
    },

    // 设置地理位置
    handleSetLocation(res) {
      this.getLocationPoint(res, "地理位置获取成功");
      this.location = {
        latitude: res.latitude,
        longitude: res.longitude,
      };
      this.getStoreList();
      storage.setStorage({ key: "location", data: this.location });
    },

    // 地理位置获取失败
    getLocationError(err) {
      this.getLocationPoint(err, "地理位置获取失败");
    },

    // 选择城市列表
    getStoreList() {
      const toast = this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        loadingType: "spinner",
      });

      cityApi
        .getSelect({
          city: this.city === CITY_NAME ? "" : this.city,
          searchKey: this.value,
          latitude: this.location.latitude, // 维度
          longitude: this.location.longitude, // 经度
          channelId: this.routerQuery.cid || "",
          wxCorpId: process.env.VUE_APP_URL_WX_CORPID_MDL,
        })
        .then((res) => {
          if (!res.data) {
            res.data = {
              cityName: CITY_NAME,
              currentCity: false,
              stores: [],
            };
          }

          this.isCurrentCity = res.data.currentCity; // 判断是否当前城市
          this.city = res.data.cityName;
          if (Array.isArray(res.data.stores) && res.data.stores.length > 0) {
            this.storeList = Object.freeze(res.data.stores);
            // isFlag 默认第一次打开选择
            // 如果当前搜索之后，不在按照第一个赋值，只能手动选择
            if (this.isCurrentCity && this.isFlag) {
              this.isFlag = false;
              const storeItem = res.data.stores[0];
              this.value = storeItem.storeName;
              this.shopNo = storeItem.storeId;
              this.getGroup(storeItem.storeId);
            }

            // 搜索其他城市，如果有值，需要展示下拉
            if (!this.isCurrentCity && this.value) {
              this.isStoreList = true;
            }
          } else {
            this.storeList = [];
            this.isStoreList = false;
          }
          toast.clear();
        })
        .catch((err) => {
          console.error("选择城市列表：", err);
          toast.clear();
          this.$toast(err.errMsg);
        });
    },

    // 获取焦点
    handleFocus() {
      if (
        !this.location.latitude &&
        !this.location.longitude &&
        this.city === CITY_NAME
      ) {
        this.handleCity();
      } else {
        if (this.isStoreList) return false;
        if (!Array.isArray(this.storeList) || this.storeList.length <= 0) {
          return false;
        }
        this.isStoreList = true;
        this.$nextTick(() => {
          this.$refs.storeLayoutRef &&
            (this.$refs.storeLayoutRef.scrollTop = 0);
        });
      }
    },

    // 过滤数据
    handleInput() {
      this.getStoreList();
    },

    // 点击门店
    handleClickStore(item) {
      this.value = item.storeName;
      this.shopNo = item.storeId;
      this.city = item.city || "城市";
      this.isStoreList = false;
      this.getGroup(item.storeId);
    },

    getGroup(id) {
      if (this.qrCodeType) {
        this.getCode(id);
      } else {
        cityApi
          .qrCodeType()
          .then((res) => {
            this.qrCodeType = res.data;
            document.title = titleOption[this.qrCodeType];
            this.getCode(id);
          })
          .catch((err) => {
            this.$toast(err.errMsg);
          });
      }
    },

    // 获取活码
    getCode(storeId) {
      // 活动类型 扫码添加员工：ADD_QR_CODE 扫码添加社群：ADD_GROUP_CHAT
      if (this.qrCodeType == 1) {
        this.noCodeText = "渠道活码";
        this.getUserCode(storeId);
        return false;
      }

      if (this.qrCodeType == 2) {
        this.noCodeText = "社群活码";
        this.getGroupCode(storeId);
        return false;
      }
    },

    // 渠道活码
    getUserCode(storeId) {
      cityApi
        .channelQrCode(storeId, {
          channelId: this.routerQuery.cid || "",
        })
        .then((res) => {
          this.qrCodeUrl = res.data.qrCodeUrl;
          this.qrCodeId = res.data.qrCodeId;
          if (res.data && res.data.qrCodeUrl) {
            this.getQrCodePoint("渠道活码");
          }
        })
        .catch((err) => {
          this.$toast(err.errMsg);
        });
    },

    // 群活码
    getGroupCode(storeId) {
      cityApi
        .groupQrCode(process.env.VUE_APP_URL_WX_CORPID_MDL, storeId)
        .then((res) => {
          this.qrCodeUrl = res.data.qrCodeUrl;
          this.qrCodeId = res.data.qrCodeId;
          if (res.data && res.data.qrCodeUrl) {
            this.getQrCodePoint("社群活码");
          }
        });
    },

    // 地址位置上报
    getLocationPoint(res, msg) {
      this.getPointData({
        configId: "e1229815b4c111eb94440242ac120003",
        bizData: JSON.stringify({
          click: msg,
          wxConfig: res,
          qrCodeType:
            this.qrCodeType || window._ICC_LBS_DATA.urlParams.qrCodeType || "",
          unionId: window._ICC_LBS_DATA.token,
          url: window.location.href || "",
        }),
      });
    },

    // 活码获取切换上报
    getQrCodePoint(message) {
      this.getPointData({
        configId: "c4354d25150411eca890b8599f4fd2b0",
        bizData: JSON.stringify({
          click: message + "/展示次数",
          cityName: this.city,
          storeId: this.shopNo,
          storeName: this.value,
          qrCodeType: this.qrCodeType,
          unionId: window._ICC_LBS_DATA.token,
          url: window.location.href || "",
        }),
      });
    },

    // 开始
    handleTouchStart() {
      if (this.timerOut) {
        clearTimeout(this.timerOut);
        this.timerOut = null;
      }
      this.timerOut = setTimeout(() => {
        if (this.isApp) {
          this.handleDownload();
        } else {
          this.getTouchCodePoint();
        }
      }, 600);
    },

    handleTouchEnd() {
      if (this.timerOut) {
        clearTimeout(this.timerOut);
        this.timerOut = null;
      }
    },

    // 长按下载图片
    handleDownload() {
      const _this = this;
      bridgeMethods.getAuth("photo").then((res) => {
        if (res.auth == 1) {
          bridgeMethods
            .startShare(
              JSON.stringify({
                imageUrl: this.qrCodeUrl,
                channels: [{ channel: 3, type: 1 }],
              })
            )
            .then(() => {
              _this.$toast("二维码已保存到相册");
              _this.getTouchCodePoint();
            });
        } else {
          _this.$toast("用户拒绝授权，无法保存二维码");
        }
      });
    },

    // 活码长按识别上报
    getTouchCodePoint() {
      const configId =
        this.qrCodeType == 1
          ? "879d71b0b4c211eb94440242ac120003"
          : "13bac941b4c311eb94440242ac120003";
      const title = this.qrCodeType == 1 ? "渠道活码" : "社群活码";
      this.getPointData({
        configId,
        bizData: JSON.stringify({
          click: "长按识别/" + title,
          qrCodeId: this.qrCodeId,
          cityName: this.city,
          storeId: this.shopNo,
          storeName: this.value,
          qrCodeType: this.qrCodeType,
          unionId: window._ICC_LBS_DATA.token,
          url: window.location.href || "",
        }),
      });
    },

    // 数据上报
    getPointData(data) {
      const options = Object.assign({}, data, {
        wxCorpId: process.env.VUE_APP_URL_WX_CORPID_MDL,
        bizParams: JSON.stringify(this.routerQuery),
      });
      updatePointData(options);
    },

    handleSelf() {
      if (this.isStoreList) {
        this.isStoreList = false;
      }
    },

    handleCity() {
      // 标识，必须是home跳转到city,不然都要重定向到首页
      this.$router.push({ name: "city", query: { isHome: "1" } });
    },
  },
  beforeDestroy() {
    this.clearInterval();
    this.handleInputDebounce = null;
    if (this.timerOut) {
      clearTimeout(this.timerOut);
      this.timerOut = null;
    }
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
